import React, { useContext, useEffect } from "react";
import classNames from 'classnames';
import { Link, useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";

/**
 * Assest
 */
import $ from "jquery";
import Layout from "../components/layout";
import './styles/_sitemap.scss';


function ContextAwareToggle({ children, eventKey, callback }) {
useEffect(() => {
$(".open-third-level").hide();

$(".thrid-level").each(function () {
var thirdlevel = $(this).find("li");
//console.log('aaaaaaaaa',thirdlevel.length)
var ifthirdlevel = thirdlevel.length
if (ifthirdlevel > 0) {
$(this).parent().addClass('has-dropdown');
}
});
$(".has-dropdown .open-third-level").show();

//toggle the component with class thrid-level
$(".open-third-level").click(function () {
if ($('.thrid-level').is(':visible')) {
$(".thrid-level").hide();
$(".plusminus").text('+');
}
if ($(this).next(".thrid-level").is(':visible')) {
$(this).next(".thrid-level").hide();
$(this).children(".plusminus").text('+');
} else {
$(this).next(".thrid-level").stop().slideToggle('slow');

$(this).children(".plusminus").text('-');
}
});
})
const currentEventKey = useContext(AccordionContext);

const decoratedOnClick = useAccordionToggle(
eventKey,
() => callback && callback(eventKey),
);

const isCurrentEventKey = currentEventKey === eventKey;

return (
<button
type="button"
className={classNames('toggle-button', { 'active': isCurrentEventKey })}
onClick={decoratedOnClick}
>toggle-button</button>
);
}

const Sitemap = (props) => {

const data = useStaticQuery(graphql`
query {
allStrapiAllMenus {
edges {
node {
id
Alias
Label
Link_Type
Show_In_Burger_Menu
URL
main_parent {
Label
Link_Type
URL
id
Show_In_Burger_Menu
}   
sub_parent {
    URL
    Label
  }                     
}
}
}
}
`);

console.log('node', data.allStrapiAllMenus.edges);

return (

<Layout>

<div className="site-map">
  <h1 style={{display:'none'}}>Site Map</h1>
<Container>
<Row>
<Accordion>
<ul className="parent-menu">
{data.allStrapiAllMenus.edges && data.allStrapiAllMenus.edges.map(({ node }, key) => {

console.log('node', node);
return <>
{
node.main_parent === null && node.Show_In_Burger_Menu === true && (
<>
<li>
{node.URL === "find-a-property" &&
<Link to="/property/for-sale/in-london/">{node.Label}</Link>
}
{node.URL != "find-a-property" &&
<Link to={`/${node.URL}/`}>{node.Label}</Link>
}
<ContextAwareToggle eventKey={node.id} />
<Accordion.Collapse eventKey={node.id}>
<Nav className="dropdown-list">
<ul>
{node.URL === "find-a-property" &&
<>
<li><Link to="/property/for-sale/in-london/">Property for Sale</Link></li>
<li><Link to="/property/to-rent/in-london/">Property to Rent</Link></li>
</>
}
{data.allStrapiAllMenus.edges.map((submenu, key2) => {
return <>
{submenu.node.sub_parent == null && submenu.node.main_parent && submenu.node.main_parent.Label === node.Label &&
<li className="second-level">
  {submenu.node.URL === 'instant-online-valuation'?
  <a href="http://valuation.anthonypepe.com/" target="_blank">{submenu.node.Label}</a>
  :
  <Link to={`/${node.URL}/${submenu.node.URL}/`}>{submenu.node.Label}</Link>
  }
<div className="open-third-level"><a className="plusminus">+</a>
</div>

<ul className="thrid-level" style={{ display: "none" }}>
{data.allStrapiAllMenus.edges.map((submenu2, key2) => {
return <>
{submenu2.node.sub_parent !== null && submenu2.node.main_parent && submenu2.node.main_parent.Label === node.Label && submenu2.node.sub_parent && submenu2.node.sub_parent.Label === submenu.node.Label &&

<li><Link to={`/${node.URL}/${submenu.node.URL}/${submenu2.node.URL}/`}>{submenu2.node.Label}</Link>
</li>
}
</>
})}
</ul>
</li>
}
</>
})}
</ul>
</Nav>
</Accordion.Collapse>
</li>
</>
)
}
</>
})}
</ul>

</Accordion>
</Row>

</Container>
</div>

</Layout>


)
}

export default Sitemap
